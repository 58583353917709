import {AdvancedVideo, AdvancedImage} from '@cloudinary/react';
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
  
  function ShortVideos(props) {

      const [loading, setLoading] = useState(true);

      useEffect(() => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      }, [])


      return (

      <div className="container-fluid-lg bgDark min-vh-100">
    
        { loading ? 
      
          <div className="spinner-wrapper">
            <AdvancedImage className='img-fluid' cldImg={props.loadViz} />
            <span className="visually-hidden">Loading...</span>
            </div> 
            :
    
    
    <div className="container-fluid videos min-vh-100">

        {/* <h1 style={{
                            fontFamily: 'Londrina Outline', 
                            color: 'black',
                            fontWeight: 'bolder', 
                            fontSize: '8vw'}} 
                            className="opacity-100 card-text wordkeep videos-header text-center p-5">Clips:</h1> */}

          <div className="g-3"> 
            <motion.div className="col-xs-12 col-sm-12 center-text-div p-5"
                        variants={props.vVariants}
                        initial='hidden'
                        whileInView='show'>
              <div className="card">
              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v4Full} controls />
              <div className="card-body videocard border border-dark border-4">
                            <p className="card-text text-white text-center">"For the winners"</p>
                          </div>    
              </div>
            </motion.div>

      
          </div>


          <div className="row g-3">
            
          <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div p-5"
                        variants={props.vVariants}
                        initial='hidden'
                        whileInView='show'>
              <div className="card">
              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v3Full} controls />
                          <div className="card-body videocard border border-dark border-4">
                            <p className="card-text text-white">"Your Vote" (Teaser)</p>
                          </div>
              </div>
            </motion.div>

          <motion.div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-text-div p-5"
                        variants={props.vVariants}
                        initial='hidden'
                        whileInView='show'>
              <div className="card">
              <AdvancedVideo className='img-fluid card-img-top autoplay' cldVid={props.v2Full} controls />
                          <div className="card-body videocard border border-dark border-4">
                            <p className="card-text text-white">"Sensory bop"</p>
                          </div>
              </div>
          </motion.div>

          </div>

        </div>}

      </div>
     
      );
    }
    
    export default ShortVideos;