import { useRef } from "react";
import Values from "./Values";
import CollaborateFor from "./CollaborateFor"
import Footer from "../Footer";
import WhiteBreak from "../Spacers/WhiteBreak";
import NavBar from "../NavBar";
import ValuesHeader from "../Spacers/ValuesHeader";
import AboutHeader from "../Spacers/AboutHeader";
import { motion, useScroll } from "framer-motion";
import AboutParagraph from "./AboutParagraph";

function AboutPage(props) {

  const element = useRef(null);
  const { scrollYProgress } = useScroll({
    target: element,
    offset: ['start 0.50', 'start start']
  })

  let aboutParagraph = 'SFMERSIVE is NYC based multimedia audiovisual artist Manon Casimir-Sainton. Using her experiences with synesthesia - a heightened sensory ability to see color when hearing sound - she creates bold, vibrant visualizations.'.split(' ')

  

  let aboutParagraph2 = 'Inspired by her love of multi-sensory experiences, she works at the intersection of color, sound, movement, and psychology. SFMERSIVE collaborates with brands, companies, and organizations looking to capture, inspire, empower, and mobilize clients. She has been commissioned by Columbia University, Astoria Music Project, The Well, and other NYC based organizations.'

    return (
 
      <div className="mainBg">

        <NavBar />

        <br/>
        <AboutHeader />
            <div className="container-fluid-lg p-5">
              <motion.div   initial={{ opacity:0 }} 
                            animate={{ opacity:1 }} 
                            transition={{ duration: 0.5, delay: 0.1 }}>
                              
              <motion.h1 className="mainFont-header aboutDesc" 
                  ref={element}
                  >
               { aboutParagraph.map( (word, i) => {
                const start = i / aboutParagraph.length;
                const end = start + (1 / aboutParagraph.length)
             
                return <AboutParagraph key={i} range={[start, end]} progress={scrollYProgress}>{word}</AboutParagraph>
               })}

               <br/> 
               {aboutParagraph2}
               </motion.h1>
              </motion.div>

            </div>
   
       
          <CollaborateFor collabVid1={props.collabVid1}
                          collabSnd2={props.collabSnd2}
                          collabMulti3={props.collabMulti3}
                          collabDig4={props.collabDig4}
                          collabPort5={props.collabPort5}
                          cVariants={props.cVariants}
                          iVariants={props.iVariants}/>
          <ValuesHeader />
          <Values  cVariants={props.cVariants}
                   iVariants={props.iVariants}/>
          <WhiteBreak/>
          <Footer/>
      </div>
      
    );
  }
  
  export default AboutPage;
  